.login-page {
  height: 100vh;
}

.login-page-bg {
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -1;
}

.login-meta-box {
  z-index: 100;
}
.main-login {
  max-width: 100%;
  max-height: auto;
  height: 100vh;
  /* border-radius: 20px; */
  overflow: auto;
}

.forgot-pass-link {
  text-decoration: none;
  color: #666666;
}
.outer-bg {
  background-color: #eee;
  /* background-image: url(../../assets/images/opams-auth-bg.png);   */
  background-size: 80%;
}
.login-bg {
  background-image: url(../../assets/images/OPPICRABg.jpg);
  /* background-image: url(../../assets/images/stock-bg.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0;
}
.login-logo {
  max-width: 80px;
}
/* .login-logo {
  max-width: 150px;
} */

@media screen and (max-width: 615px) {
  .col-6.col-sm-6.col-md-5.login-section {
    width: 100%;
  }
  .login-logo {
    max-width: 100px;
  }
  .opams-text {
    font-size: 20px;
  }
}

button.px-4.login-button {
  color: white;
  background-color: rgb(11 146 75);
  border-color: rgb(11 146 75);
}
button.px-4.cancel-button {
  color: #685f5f;
  background-color: #ffffff;
  border-color: gray;
}
h4.welcome-header-login {
  font-size: 23px;
  letter-spacing: 1px;
  font-family: 'Roboto';
  font-weight: 400;
  text-align: center;
}

/* eye icon */
.pass-wrapper-login {
  position: relative;
  width: 100%;
}

svg.eye-react-icons-login {
  position: absolute;
  right: 0.75em;
  top: 0.67em;
  z-index: 9;
}
.forgot-password {
  color: gray;
}

.forgot-password:hover {
  color: #033454 !important;
}

.line-break {
  height: 10px;
  color: black;
}

.login-form-field:focus {
  box-shadow: unset !important;
  border-color: unset !important;

}